.controlPanel {
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 20px;
  margin: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  width: 900px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* center: items are centered in the cross-axis */
  align-content: space-between;
}
.toggleContainer {
  margin: 10px;
}
.button {
  width: 100%;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  /* margin: 10px 0; */
  /* margin-top: 30px;
  margin-right: 10px; */
  margin: 30px auto 0px auto;
  transition-duration: 0.4s;
  border-radius: 5px;
}

.button:hover {
  background-color: #0056b3;
}
.submitButton {
  margin-top: 10px;
  width: 100%;
  margin-left: 10px;
}

/* .toggleButton {
  margin-top: 30px;
  width: 100%;
  margin-left: 10px;
} */
.selectContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  justify-content: center;
  margin-bottom: 20px;
}
.SendListTokenContainer {
  margin: 10px;
}

.selectLabel {
  margin-bottom: 10px;
}

.select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.submitButton {
  margin-top: 30px;
}

.cpbg {
    border: 10px  double red;  /* Adjust to your liking */
    background-color: #eeee;
    margin-bottom: 10vw;  /* Adjust to your liking */
    width: 10%;
    text-align: center;  /* Adjust to your liking */

}

.cpbox {
    border: 10px  solid #cccc;  /* Adjust to your liking */
    background-color: #eeee;
    margin-bottom: 2vw;  /* Adjust to your liking */
    width: 10%;
    text-align: center;  /* Adjust to your liking */

}
