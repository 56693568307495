/* App.css */
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f1f1f1;
  font-family: "Arial", sans-serif;
}

/* ConnectWallet.css */
.connect-wallet {
  margin-bottom: 30px;
}

.connect-button {
  padding: 12px 24px;
  font-size: 18px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.connect-button {
  background-color: #008000;
}
.connect-button:hover {
  background-color: #006600;
}
.disconnect-button {
  background-color: #ff0000;
}
.connected-wallet p {
  margin-right: 10px;
  font-size: 18px;
  color: #333333;
}
.disconnect-button:hover {
  background-color: #cc0000;
}
.connected-wallet {
  display: flex;
  align-items: center;
}

.connected-wallet p {
  margin-right: 10px;
}
.connected-wallet {
  display: flex;
  align-items: center;
}
.disconnect-button {
  margin-top: 3px;
  padding: 12px 24px;
  font-size: 18px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.stakingOptionsYield-Container {
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translate(-50%, -50%);

  margin: 30px;
  display: flex;
  flex-direction: row;
}

/* StakingOptions.css */
.staking-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.staking-options select {
  margin-bottom: 10px;
  padding: 8px 16px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  width: 275px;
}

.staking-options input {
  margin-bottom: 10px;
  padding: 8px 16px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  width: 275px;
}

.staking-options p {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  color: #333333;
}
.submit-button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.status-message {
  font-size: 16px;
  color: #333; /* change to the color you prefer */
  background-color: #f9f9f9; /* change to the background color you prefer */
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin: 20px 0;
}
.centered-message {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 30px;
  align-items: center;
  height: 100px; /* You can set this to whatever height you prefer */
}
.staking-options button {
  padding: 12px 24px;
  font-size: 18px;
  background-color: #008000;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 300px;
  transition: background-color 0.2s ease-in-out;
}
.staking-options button:hover {
  background-color: #006600;
}

.connect-wallet-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* ExpectedYield.css */
.expected-yield {
  margin-bottom: 20px;
}

.expected-yield p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  font-size: 16px;
  color: #ffffff;
}

body {
  font-family: "Arial", sans-serif;
  background-color: #f2f2f2;
  color: #333;
}

.amountContainer {
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
}

.amountContainer p {
  font-size: 18px;
  margin-bottom: 20px;
}

.amountContainer input {
  width: 80%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.amountContainer button {
  width: 80%;
  padding: 10px;
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s;
}

.amountContainer button:hover {
  background-color: #0056b3;
}

.mainbg {
    border: 10px  double #FFFFFF;  /* Adjust to your liking */
    background-color: #fffbcf;
    margin: 10px;  /* Adjust to your liking */
    width: 10%;
    text-align: center;  /* Adjust to your liking */

}

