/* TokenSelector.css */

.token-selector {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
}

.token-form {
  display: flex;
  flex-direction: column;
}

.token-list {
  margin-bottom: 20px;
}

.token-item {
  display: block;
  margin: 10px 0;
}

.token-checkbox {
  margin-right: 10px;
}

.submit-button {
  width: 100%;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  border-radius: 5px;
}

.submit-button:hover {
  background-color: #45a049;
}

.feedback-message {
  margin-bottom: 20px;
  color: red;
}

.token-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.token-table thead {
  background-color: #f4f4f4;
}

.token-table th,
.token-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.token-row:hover {
  background-color: #f6f6f6;
}
.token-selector {
  display: flex;
  flex-direction: column;
  align-items: center; /* This will center align all the child elements */
}

.token-form,
.token-table {
  width: 80%; /* This sets a consistent width for both the select box and table */
  margin: 0 auto; /* This centers them horizontally */
}

.token-form {
  margin-bottom: 20px; /* This adds some space between the form and the table */
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 100px;
}

.scrollable-div {
  max-height: 400px; /* Change this as per your requirement */
  overflow-y: auto;
}

.mainbg {
  border: 10px double #ffffff; /* Adjust to your liking */
  background-color: #fffbcf;
  margin: 10px; /* Adjust to your liking */
  width: 10%;
  text-align: center; /* Adjust to your liking */
  border-radius: 100px;
}

.bar-selector {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
}

.bar-selector-button {
  flex-grow: 1;
  border: none;
  padding: 10px;
  background: #fff;
  cursor: pointer;
}

.bar-selector-button:not(:first-child) {
  border-left: 1px solid #ddd;
}

.bar-selector-button.active {
  background: #f0f0f0;
}

.token-selector-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.token-selector-tabs button {
  font-size: 18px;
  padding: 10px 20px;
  background-color: #f2f2f2;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.token-selector-tabs button.active {
  background-color: #ddd;
}
.token-selector-tabs button.active {
  background-color: #ddd;
}

.token-selector-tabs button.wallet {
  background-color: #c1e6c6;
}

.token-selector-tabs button.sent {
  background-color: #ffbaba;
}
.claim-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.claim-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.claim-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.claim-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.claim-table th,
.claim-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.claim-checkbox {
  margin-bottom: 10px;
}

.claim-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.wallet-view,
.sent-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.wallet-header,
.sent-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.wallet-content,
.sent-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet-table,
.sent-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.wallet-table th,
.wallet-table td,
.sent-table th,
.sent-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.wallet-checkbox,
.sent-checkbox {
  margin-bottom: 10px;
}

.wallet-button,
.sent-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.scrollable {
  overflow: auto;
  max-height: 300px; /* Set a max height for the scrollable area */
}
.wallet-header-bold {
  font-weight: bold;
  margin-bottom: 10px;
}
.wallet-info p {
  text-align: left;
}


.enterBox {
    border: 10px  ;  /* Adjust to your liking */
    background-color: #fffbcf;
    padding: 1px;
    width: 10%;
    height: auto;
    border-radius: 100px;
    text-align: center;  /* Adjust to your liking */
}
